.p-container{
    width: 100vw;
    height: 100vh;
    background-color: rgb(29, 49, 82);
    color: white;
    display: flex;
    justify-content: center;
    gap: 100px;
}
.project-heading{
    margin-top: 7vh;
}
.p-container h1{
    padding-top: 40px;
    color: rgb(244, 221, 15);
    text-align: center;
}
.project{
    margin-top: 20px;
    /* margin-left: 150px; */
}
.project span{
    font-weight: 600;
    color: aqua;
}
.project a{
    color: white;
    font-weight: 500;
}
.project-box{
    display: flex;
}
.project-box pre{
    font-size: 23px;
    font-weight: 600;
    color: rgb(255, 0, 85);
}
.project-box h3{
    color: aqua;
    /* font-weight: 550; */
}
.project-img{
    width: 40vw;
    height: 65vh;
    /* margin-left: 100px; */
    margin-top: 130px;
    border: 2px solid rgb(27, 57, 252);
    box-shadow: -5px 5px 5px 5px rgb(27, 57, 252);
}



/* --------smaller-screen-------- */

@media screen and (max-width:1200px){
    .p-container{
        gap: 50px;
    }
}
@media screen and (max-width:1000px){
    .p-container{
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .project-img{
        display: none;
    }
    .project-heading{
        margin: 0;padding: 0;
        width: fit-content;
    }
}


@media screen and (max-width:450px){
    .project-heading{
        margin-inline: 10px;
    }
}
@media screen and (max-width:400px){
    .project span{
        font-size: 14px;
        font-weight: 600;
        color: aqua;
    }
    .project-box pre{
        font-size:20px;
        font-weight: 600;
        color: rgb(255, 0, 85);
    }
    .project-box h3{
        font-size: 16px;
    }
    .project a{
        font-size: 12px;
        color: white;
        font-weight: 500;
    }
}
@media screen and (max-width:380px){
    .project span{
        font-size: 12px;
        font-weight: 600;
        color: aqua;
    }
    .project-box pre{
        font-size:16px;
        font-weight: 600;
        color: rgb(255, 0, 85);
    }
    .project-box h3{
        font-size: 14px;
    }
    .project a{
        font-size: 14px;
        color: white;
        font-weight: 500;
    }
}
.service-container{
    width: 100vw;
    height: 60vh;
    background-color: rgb(29, 49, 82);
}
.service-heading{
    font-size: 40px;
    text-align: center;
    font-weight: 700;
    color: white;
    padding-top: 30px;
    margin-top: 10vh;
}
.service-box{
    display: flex;
    justify-content: center;
    /* margin-left: 10vw; */
    margin-top: 5vh;
}
.box{
    width: 25vw;
    height: 25vh;
    margin: 10px;
    border-radius: 10px;
    border: 3px solid rgb(27, 57, 252);
    box-shadow: 5px 5px 5px 5px rgb(27, 57, 252);
}
.box h3{
 color: aqua;
 margin: 10px 10px;
 font-size: 20px;
}
.box p{
   margin-left: 10px;
   margin-right: 10px;
   margin-top: 10px;
   font-weight: 500;
   color: white;
}



/* -------smaller-screen-------- */
@media screen and (max-width:800px){
.box h3{
    font-size: 18px;
   }
   .box p{
     font-size: 15px;
   }
}
@media screen and (max-width:680px){
    .box h3{
        font-size: 16px;
       }
       .box p{
         font-size: 13px;
       }
    }
    @media screen and (max-width:580px){
        .box h3{
            font-size: 14px;
           }
           .box p{
             font-size: 12px;
           }
        }

@media screen and (max-width:480px){
    .service-container{
   width: 100vw;
   height:fit-content;
    }
    .service-box{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5vh;
    }
    .box{
        width:300px;
        height: 250px;

    }
    .box h3{
        margin: 10px 10px;
        font-size: 22px;
       }
       .box p{
          margin-left: 10px;
          margin-right: 10px;
          margin-top: 10px;
          font-size: 16px;
          font-weight: 500;
          color: white;
       }
   
}
@media screen and (max-width:380px){
    .box{
        width:250px;
        height: 200px;

    }
}
@media screen and (max-width:290px){
    .box{
        width:200px;
        height: 150px;

    }
    .box h3{
        font-size: 18px;
       }
       .box p{
        font-size: 12px;
       }
}


.home-container{
    width: 100vw;
    height: 92vh;
    background-color: rgb(29, 49, 82);
    display: flex;
    justify-content: space-between;
}
.left{
    width: 45vw;
    height: 65vh;
    margin-left: 100px;
    margin-top: 20vh;
}
.left p:nth-child(1){
    color: white;
    font-size: 30px;
    font-weight: 500;
}
.left p:nth-child(2){
    color: rgb(244, 221, 15);
    font-size: 50px;
    font-weight: 550;
    margin-top: -10px;
}

.span1{
    color: white;
    font-size: 30px;
    font-weight: 500;
    margin-top: -10px;
}
.span2{
    color: aqua;
    font-size: 30px;
    font-weight: 500;
}
.objective{
    color: white;
    font-size: 15px;
    font-weight: 500;
    margin-top: 30px;
}

.second-circle{
    width: 500px;
    height:415px;
    border: 4px solid white;
    margin-right: 100px;
    margin-top: 20vh;
    color: 8,90%,63%;
    
}
.image-container{
    width: 500px;
    height: 400px;
    margin-left: 7.5px;
    margin-top: 7.5px;

}
.image-container img{
    width: 485px;
    height: 400px;
}
.icons{
    
    display: flex;
    text-align: center;
    margin-top: 20px;
}
.icons span{
    width: 40px;
    height: 40px;
    border: 2px solid aqua;
    border-radius: 50%;
    margin: 10px;
   
}
.icons span:hover{
    
    filter: brightness(200%);
    border: 3px solid rgb(27, 57, 252);
    box-shadow: 2px 2px 2px 2px rgb(27, 57, 252);
}
.icon{
    margin-top: 5px;
    width: 30px;
    height: 30px;
    color: aqua;
}
.btn1 button{
    background-color: aqua;
    width: 200px;
    height: 40px;
    border: none;
    border-radius: 10px;
    font-weight: 700;
    font-size: 15px;
    margin-top: 20px;
    margin-left: 20px;
    

}
.btn1 button:hover{
    color: aqua;
    width: 200px;
    height: 40px;
    background-color: black;
    border: 3px solid rgb(27, 57, 252);
    box-shadow: 3px 3px 3px 3px rgb(27, 57, 252);
    cursor: pointer;
}
.btn1 {
    width:300px ;
    height: 100px;
}


/* -------smaller screen-------------- */


@media screen and (max-width:1000px){
    .left{
      margin-left: 30px;
    }   
    .right{
        margin-right: -20px;
    }
    .first-circle{
        /* width: 400px;
        height:365px; */
        background-color: antiquewhite;
    }
    .second-circle{
        width: 400px;
        height:365px;
    }
    .image-container{
        width: 400px;
        height: 350px;
    }
    .image-container img{
        width: 385px;
        height: 350px;
    }
    .left p:nth-child(1){
        font-size: 25px;
    }
    .left p:nth-child(2){
        font-size: 35px;
    }
    .span1,.span2{
        font-size: 25px;
    }
}
@media screen and (max-width:750px){
    .home-container{
        display: block;
        width: 100vw;
        height: 120vh;
    }
    .left{
        width: 100vw;
        margin-top: 10vh;
    }
    .right{
        margin-top: -30vh;
    }
    .left p:nth-child(1){
        font-size: 30px;
    }
    .left p:nth-child(2){
        font-size: 50px;
    }
    
    .span1{
        font-size: 30px;
    }
    .span2{
        font-size: 30px;
    }
    .right{
        width: 100vw;
        display: flex;
        justify-content: center;
    }
    .second-circle{
        margin-right: 0px;
    }
    
}
@media screen and (max-width:500px){
    .home-container{
        display: flex;
        flex-direction: column;
        margin-top: 8vh;
        height: 90vh;
    }
    .left{
      width: 100vw;
      margin-left: 20px;
      margin-right: 30px;
      margin-top: 45px;
    }
    .span1{
        color: white;
        font-size: 16px;
        font-weight: 600;
    }
    .span2{
        color: aqua;
        font-size: 16px;
        font-weight: 600;
    }
    .objective{
        color: white;
        font-size: 12px;
        font-weight: 500;
        margin-top: 20px;
    }
    .btn1 button{
        background-color: aqua;
        
        margin-top: 5px;
        margin-left: 20px;
    }

  .right{
    width: 100vw;
    }
    .first-circle{
       height: 400px;
    }
    .second-circle{
        width: 340px;
        height:280px;
        border: 4px solid white;
        margin-top: 0px;
        margin-left: 25px;
    }
    .image-container{
        width: 330px;
        height: 270px;
        margin-left: 5px;
        margin-top: 5px;
    }
    .image-container img{
        width: 330px;
        height: 270px;
       
        
    }
}
@media screen and (max-width:450px){
.right{
    width: 100vw;
    display: flex;
    justify-content: center;
    
}
.second-circle{
    margin-left: 0px;
}
}